import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import "./reset.css"
import SEO from "../components/seo"
import Home from "../components/Home"
import RenanCerpe from "../components/RenanCerpe"
import NationalRecognition from "../components/NationalRecognition"
import CuboMagicoExpress from "../components/CuboMagicoExpress"
import StepList from "../components/StepList"
import SecureYouPlace from "../components/SecureYouPlace"
import LetsTalk from "../components/LetsTalk"

const IndexPage = () => (
  <Layout>
    <SEO title="Cubo Mágico Express" />
    <Home />
    <RenanCerpe />
    <NationalRecognition />
    <CuboMagicoExpress />
    <StepList />
    <SecureYouPlace />
    <LetsTalk />
    <Helmet>
      <script src="//code.jivosite.com/widget/NQncPQxpdO" async></script>
    </Helmet>
  </Layout>
)

export default IndexPage
