import styled from "styled-components"
import media from "styled-media-query"
import BgMobile from "../../images/home-bg-mobile.jpg"
import BgDesktop from "../../images/home-bg-desktop.jpg"

export const HomeContent = styled.section`
  width: 100%;
  min-height: 100vh;
  background: url(${BgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 22px 0;
  box-sizing: border-box;

  ${media.greaterThan("large")`
    background: url(${BgDesktop});
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 150px;
  `}
`
