import React from "react"
import { Text } from "../styled"
import styled from "styled-components"
import media from "styled-media-query"

const RecognitionNumbers = ({ number, svgIcon, title }) => {
  return (
    <>
      <RecognitionItem>
        <div className="number-wrapper">
          <span className="number">{number}</span>
          {svgIcon}
        </div>
        <RecognitionItemTitle>{title}</RecognitionItemTitle>
      </RecognitionItem>
    </>
  )
}

export default RecognitionNumbers

export const RecognitionItem = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  align-items: center;
  ${media.greaterThan("large")`
    width: 100%;
    max-width: 180px;
  `}

  &:last-child {
    .number {
      letter-spacing: -20px;
    }
    svg {
      margin-left: 0;
    }
  }

  .number {
    font-family: Squada One;
    font-size: 132px;
    color: #f1f1f1;
  }

  svg {
    margin-left: -15px;
  }
`

export const RecognitionItemTitle = styled(Text)`
  font-weight: bold;
  width: 100%;
  max-width: 140px;
  text-align: center;
`
