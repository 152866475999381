import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Container, SectionTitle, Button, Text } from "../styled"

const LetsTalk = () => {
  return (
    <FooterPage>
      <Container title>
        <SectionTitle>vamos bater um papo?</SectionTitle>
        <div className="social-buttons">
          <a
            href="https://www.youtube.com/user/renanmondinicerpe"
            target="_blank"
          >
            <Button social aria-label="Youtube">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.447 8.68752C31.0553 6.98569 29.6633 5.73014 27.9876 5.54284C24.0194 5.09988 20.003 5.09757 16.0051 5.09988C12.0066 5.09757 7.98951 5.09988 4.02135 5.54284C2.34661 5.73014 0.955623 6.98569 0.563861 8.68752C0.00627615 11.1111 0 13.7566 0 16.2519C0 18.7472 0 21.3924 0.557585 23.8156C0.948686 25.5171 2.34001 26.7727 4.01573 26.9603C7.98357 27.4036 12.0003 27.4059 15.9988 27.4036C19.9981 27.4059 24.0138 27.4036 27.9813 26.9603C29.656 26.773 31.0483 25.5174 31.4401 23.8156C31.998 21.392 32 18.7468 32 16.2519C32 13.7566 32.0046 11.1111 31.447 8.68752ZM11.8705 21.2186C11.8705 17.6789 11.8705 14.1762 11.8705 10.6361C15.2619 12.4056 18.6309 14.1633 22.0437 15.9437C18.6414 17.7083 15.2708 19.4554 11.8705 21.2186Z"
                  fill="#212121"
                />
              </svg>
            </Button>
          </a>
          <a href="https://pt-br.facebook.com/renancerpe/" target="_blank">
            <Button social aria-label="Facebook">
              <svg
                width="14"
                height="31"
                viewBox="0 0 14 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.9375 10.9875V7.34204C8.9375 6.33588 9.6935 5.51929 10.625 5.51929H12.3125V0.962402H8.9375C6.14131 0.962402 3.875 3.41036 3.875 6.43066V10.9875H0.5V15.5444H3.875V30.1265H8.9375V15.5444H12.3125L14 10.9875H8.9375Z"
                  fill="#212121"
                />
              </svg>
            </Button>
          </a>
          <a href="https://www.instagram.com/renancerpe/" target="_blank">
            <Button social aria-label="Instagram">
              <svg
                width="29"
                height="32"
                viewBox="0 0 29 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.087 0.15625H8.23958C3.87667 0.15625 0.327148 3.99039 0.327148 8.70291V22.5798C0.327148 27.2925 3.87667 31.1265 8.23958 31.1265H21.087C25.4502 31.1265 28.9998 27.2923 28.9998 22.5798V8.70291C28.9999 3.99039 25.4502 0.15625 21.087 0.15625ZM26.456 22.5798C26.456 25.7774 24.0475 28.3786 21.0872 28.3786H8.23958C5.27942 28.3788 2.87112 25.7774 2.87112 22.5798V8.70291C2.87112 5.50554 5.27942 2.90408 8.23958 2.90408H21.087C24.0473 2.90408 26.4558 5.50554 26.4558 8.70291V22.5798H26.456Z"
                  fill="#212121"
                />
                <path
                  d="M14.6636 7.66016C10.5897 7.66016 7.27539 11.2403 7.27539 15.6411C7.27539 20.0416 10.5897 23.6216 14.6636 23.6216C18.7375 23.6216 22.0518 20.0416 22.0518 15.6411C22.0518 11.2403 18.7375 7.66016 14.6636 7.66016ZM14.6636 20.8733C11.9926 20.8733 9.81936 18.5261 9.81936 15.6409C9.81936 12.7554 11.9924 10.408 14.6636 10.408C17.3347 10.408 19.5078 12.7554 19.5078 15.6409C19.5078 18.5261 17.3346 20.8733 14.6636 20.8733Z"
                  fill="#212121"
                />
                <path
                  d="M22.3616 5.33057C21.8715 5.33057 21.39 5.54488 21.0439 5.92038C20.696 6.29405 20.4961 6.81426 20.4961 7.34546C20.4961 7.87501 20.6962 8.39503 21.0439 8.77054C21.3899 9.14421 21.8715 9.36035 22.3616 9.36035C22.8535 9.36035 23.3334 9.14421 23.6811 8.77054C24.0287 8.39503 24.2272 7.87483 24.2272 7.34546C24.2272 6.81426 24.0287 6.29405 23.6811 5.92038C23.3351 5.54488 22.8535 5.33057 22.3616 5.33057Z"
                  fill="#212121"
                />
              </svg>
            </Button>
          </a>
        </div>
        <BottomBar>
          <Text>Renan Cerpe</Text>
          <a href="https://www.cuberbrasil.com/" target="_blank">
            visite minha loja online
          </a>
        </BottomBar>
      </Container>
    </FooterPage>
  )
}

export default LetsTalk

export const BottomBar = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.greaterThan("large")`
    margin-top: 230px;
    flex-direction: row;
    align-items: center;
  `}

  ${Text} {
    font-family: Squada One;
    font-size: 44px;
    color: #f1f1f1;
  }

  a {
    font-size: 18px;
    font-family: Lato;
    color: #1eadef;
    text-decoration: none;
    display: inline-block;
    font-weight: bold;
    ${media.greaterThan("large")`
      margin-left: 33px;
    `}
  }
`

export const FooterPage = styled.footer`
  background-color: #212121;
  padding: 55px 0;

  ${SectionTitle} {
    color: #e5e5e5;
    font-size: 124px;
    opacity: 0.2;

    ${media.greaterThan("large")`
      font-size: 244px;
      line-height: 0.75em;
    `}
  }

  .social-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    ${media.greaterThan("large")`
      justify-content: flex-end;
      margin-top: -200px;
      ${Button}{
        margin: 0 12px;
        &:last-child {
          margin-right: 0;
        }
      }
    `}
  }
`
