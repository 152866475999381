import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Container, SectionTitle } from "../styled"
import ProgramsCard from "../ProgramsCard"
import img1d from "../../images/renan-pictures/1d.jpg"
import img1m from "../../images/renan-pictures/1m.jpg"
import img2d from "../../images/renan-pictures/2d.jpg"
import img2m from "../../images/renan-pictures/2m.jpg"
import img3d from "../../images/renan-pictures/3d.jpg"
import img3m from "../../images/renan-pictures/3m.jpg"
import img4d from "../../images/renan-pictures/4d.jpg"
import img4m from "../../images/renan-pictures/4m.jpg"
import img5d from "../../images/renan-pictures/5d.jpg"
import img5m from "../../images/renan-pictures/5m.jpg"
import img6d from "../../images/renan-pictures/6d.jpg"
import img6m from "../../images/renan-pictures/6m.jpg"
import img7d from "../../images/renan-pictures/7d.jpg"
import img7m from "../../images/renan-pictures/7m.jpg"
import img8d from "../../images/renan-pictures/8d.jpg"
import img8m from "../../images/renan-pictures/8m.jpg"

const NationalRecognition = () => {
  const programsCardLeft = [
    "Domingão do Faustão | GLOBO",
    "Fantástico | GLOBO",
    "Globo Esporte | GLOBO",
    "Eliana | SBT",
    "Silvio Santos | SBT",
  ]

  const programsCardRight = [
    "The Noite com Danillo Gentili | SBT",
    "Os Incríveis | NAT GEO",
    "caldeirão do huck | globo",
    "Legendários | RECORD",
    "Programa da Sabrina | RECORD",
  ]

  const galleryFirstRow = [
    {
      urlMobile: img4m,
      urlDesktop: img4d,
      imageAlt: "Renan Cerpe no Programa da Sabrina Sato",
    },

    {
      urlMobile: img2m,
      urlDesktop: img2d,
      imageAlt: "Renan Cerpe sentado com o Danilo Gentili",
    },
    {
      urlMobile: img3m,
      urlDesktop: img3d,
      imageAlt: "Renan Cerpe e Silvio Santos",
    },
    {
      urlMobile: img6m,
      urlDesktop: img6d,
      imageAlt: "Renan Cerpe em Nova Iorque",
    },

    {
      urlMobile: img1m,
      urlDesktop: img1d,
      imageAlt: "Renan Cerpe no Caldeiração do Hulk!",
    },
    {
      urlMobile: img2m,
      urlDesktop: img2d,
      imageAlt: "Renan Cerpe sentado com o Danilo Gentili",
    },
  ]

  const gallerySecondRow = [
    {
      urlMobile: img8m,
      urlDesktop: img8d,
      imageAlt: "Renan Cerpe na Rede Globo",
    },
    {
      urlMobile: img5m,
      urlDesktop: img5d,
      imageAlt:
        "Renan Cerpe mostrando seu retrato do Danilo Gentili feito de cubo mágico",
    },
    {
      urlMobile: img1m,
      urlDesktop: img1d,
      imageAlt: "Renan Cerpe no Caldeiração do Hulk!",
    },

    {
      urlMobile: img7m,
      urlDesktop: img7d,
      imageAlt: "Renan Cerpe e Danilo Gentili",
    },

    {
      urlMobile: img5m,
      urlDesktop: img5d,
      imageAlt:
        "Renan Cerpe mostrando seu retrato do Danilo Gentili feito de cubo mágico",
    },
    {
      urlMobile: img6m,
      urlDesktop: img6d,
      imageAlt: "Renan Cerpe em Nova Iorque",
    },
  ]

  return (
    <>
      <NationalWhite>
        <NationalRegocnitionAnchor id="na-midia" />
        <Container title>
          <SectionTitle>Reconhecimento nacional</SectionTitle>
        </Container>
        <Container middle>
          <div className="cards-wrapper">
            <ProgramsCard programs={programsCardLeft} />
            <ProgramsCard programs={programsCardRight} p />
          </div>
        </Container>
        <div className="gallery-images">
          <ul className="row">
            {galleryFirstRow.map((item, index) => (
              <li className="item" key={index}>
                <img
                  srcSet={`${item.urlMobile} 500w, ${item.urlDesktop} 1100w`}
                  alt={item.imageAlt}
                  className="image"
                />
              </li>
            ))}
          </ul>
          <ul className="row">
            {gallerySecondRow.map((item, index) => (
              <li className="item" key={index}>
                <img
                  srcSet={`${item.urlMobile} 500w, ${item.urlDesktop} 1100w`}
                  alt={item.imageAlt}
                  className="image"
                />
              </li>
            ))}
          </ul>
        </div>
      </NationalWhite>
    </>
  )
}

export default NationalRecognition

const NationalRegocnitionAnchor = styled.div`
  position: absolute;
  top: -150px;
  left: 0;
`

export const NationalWhite = styled.div`
  position: relative;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  margin-top: 48px;
  box-sizing: border-box;

  ${SectionTitle} {
    box-sizing: border-box;
    transform: translateY(-35px);
    ${media.greaterThan("large")`
      max-width: 610px;
      transform: translateY(-68px);
    `}
  }

  .cards-wrapper {
    ${media.greaterThan("large")`
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;
    `}
  }

  .gallery-images {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 150%;
    position: relative;
    z-index: 1;
    ${media.greaterThan("large")`
      margin-top: -30px;
    `}
    .row {
      display: flex;
      overflow-x: auto;
      &:first-child {
        transform: translateX(-50px);
        ${media.greaterThan("large")`
          transform: translateX(-112px);
        `}
      }
      &:last-child {
        margin-top: 20px;
        transform: translateX(-90px);
        ${media.greaterThan("large")`
          transform: translateX(-290px);
          margin-top: 64px;
        `}
      }
    }

    .row::-webkit-scrollbar {
      display: none;
    }

    .item {
      margin: 0 10px;
      border-radius: 6px;
      ${media.greaterThan("large")`
        margin: 0 42px ;
      `}

      .image {
        border-radius: 6px;
        max-width: 391px;
        max-height: 362px;
      }
    }
  }
`
