import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const ProgramsCard = ({ programs }) => {
  return (
    <>
      <WhiteCard>
        <ul className="list">
          {programs.map((program, index) => (
            <li className="item" key={index}>
              {program}
            </li>
          ))}
        </ul>
      </WhiteCard>
    </>
  )
}

export default ProgramsCard

export const WhiteCard = styled.div`
  border-radius: 4px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.35);
  width: 100%;
  padding: 30px 15px;
  position: relative;
  background-color: #f1f1f1;

  ${media.greaterThan("large")`
    max-width: 422px;
    height: 410px;
    max-height: 410px;
    padding: 45px;
  `}

  &:first-child {
    padding-top: 0;
    margin-right: 40px;
    ${media.greaterThan("large")`
      padding-top: 45px;
      margin-right: 0;
    `}
  }

  &:last-child {
    margin-top: 40px;
    ${media.greaterThan("large")`
      margin: 0;
    `}
  }

  list {
    display: flex;
    flex-direction: column;
  }

  .item {
    padding-top: 22px;
    font-family: Lato;
    font-size: 18px;
    color: #171717;
    font-weight: 400;
    text-transform: lowercase;
    ${media.greaterThan("large")`
      font-size: 22px;
    `}

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #1eadef;
      margin-top: 22px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`
