import React from "react"
import * as S from "./styled"
import { Container, Button } from "../styled"
import styled from "styled-components"
import media from "styled-media-query"
import scrollTo from "gatsby-plugin-smoothscroll"

const Home = () => (
  <>
    <S.HomeContent>
      <HomeContainer>
        <HomeMainContent>
          <MainTitle>
            <div className="text">Aprenda a resolver o</div>
            <div className="cubic"> cubo mágico</div>
            <div className="text">em apenas 1 hora &#129304;</div>
          </MainTitle>
          <HomeButtonWrapper>
            <Button
              yellow
              onClick={() => scrollTo("#renan-cerpe")}
              aria-label="quero resolver o cubo"
            >
              <div className="text">quero resolver o cubo</div>
            </Button>
            <SubTitle>
              com <div className="name"> renan cerpe</div>, <br />
              cubista com 10 recordes <br />
              na américa latina
            </SubTitle>
          </HomeButtonWrapper>
        </HomeMainContent>
      </HomeContainer>
    </S.HomeContent>
  </>
)

export default Home

export const HomeMainContent = styled.div``

export const HomeContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
  margin-top: -80px;
`

export const MainTitle = styled.h1`
  font-family: Lato;
  font-weight: 900;
  color: #f1f1f1;
  text-transform: lowercase;
  font-size: 32px;
  text-shadow: 0 4px 14px rgba(0, 0, 0, 0.45);
  ${media.greaterThan("large")`
    font-size: 46px;
  `}

  > .text {
    ${media.greaterThan("large")`
      margin-left: 115px;
    `}
    &:first-child {
      width: 155px;
      ${media.greaterThan("large")`
        width: 500px;
        margin-left: 52px;
      `}
    }
  }

  > .cubic {
    font-family: Squada One;
    font-size: 99px;
    color: #1eadef;
    text-transform: uppercase;
    text-shadow: 0 4px 46px rgba(0, 0, 0, 0.95);
    line-height: 0.9em;

    ${media.greaterThan("large")`
      font-size: 164px;
    `}
  }
`

export const SubTitle = styled.h2`
  display: none;
  ${media.greaterThan("large")`
    display: block;
    font-family: Lato;
    color: #f1f1f1
    font-size: 18px;
    text-transform: lowercase;
    margin-left: 20px;
    line-height: 1.3em;

    > .name{
      font-weight: bold;
      display: inline-block;
    }
    
  `}
`

export const HomeButtonWrapper = styled.div`
  display: flex;
  margin-top: 18px;
  ${media.greaterThan("large")`
    margin-top: 58px;
    margin-left: 115px;
  `}
`
