import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Container, SectionTitle, Text, Button } from "../styled"
import banner from "../../images/renan-erno.jpg"

const SecureYouPlace = () => {
  return (
    <SecureYouPlaceBg>
      <SecureYourPlaceAnchor id="garanta-sua-vaga" />
      <Container>
        <SectionTitle>garanta sua vaga</SectionTitle>
        <CurseDescriptionWrapper>
          <li className="item">
            <Text>
              <span className="title"> Curso relâmpago </span>
              aprenda em 1h 🚀
            </Text>
          </li>
          <li className="item">
            <Text>
              <span className="title"> metodologia </span>
              de fácil aprendizado 🙌
            </Text>
          </li>
          <li className="item">
            <Text>
              <span className="title"> acompanhamento </span>
              exclusivo com o renan cerpe 💥
            </Text>
          </li>
        </CurseDescriptionWrapper>
        <img
          src={banner}
          alt="Renan palestrando com o fundo do Erno Rubik"
          className="banner"
        />
      </Container>
      <Container title>
        <BlueCard id="clique-para-comecar">
          <div className="first-content">
            <a
              href="https://pay.hotmart.com/J18979817U?checkoutMode=10"
              target="_blank"
            >
              <Button white>
                <div className="text">
                  quero garantir <br /> minha vaga
                </div>
              </Button>
            </a>
          </div>
          <div className="divider"></div>
          <div className="last-content">
            <Text>
              mas corre pois as vagas são limitadas e a próxima turma já vai
              fechar &#128561;
            </Text>
          </div>
        </BlueCard>
      </Container>
    </SecureYouPlaceBg>
  )
}

export default SecureYouPlace

const SecureYourPlaceAnchor = styled.div`
  position: absolute;
  top: -140px;
  left: 0;
`

export const BlueCard = styled.div`
  background-color: #1eadef;
  padding: 37px 26px 58px 26px;
  ${media.greaterThan("large")`
    padding: 74px 0 92px 114px;
    display: flex;
    margin-top: 100px;
  `}
  .divider {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 75px 0 55px 0;
    ${media.greaterThan("large")`
      margin:  0 71px 0 47px;
      width: 2px;
      height: 230px;
    `}
  }

  .first-content {
    ${Text} {
      line-height: 2.1em;
      font-size: 22px;
      .price {
        font-weight: bold;
        display: block;
        font-size: 52px;
      }
    }
    ${Button} {
      margin-top: 37px;
    }
  }

  .last-content {
    ${media.greaterThan("large")`
      max-width: 330px;
    `}
    ${Text} {
      color: #171717;
      font-size: 22px;
      font-weight: bold;
      ${media.greaterThan("large")`
        font-size: 34px;
      `}
    }

    > .timer {
      background-color: #171717;
      padding: 25px 0;
      border-radius: 4px;
      margin-top: 33px;
      ${media.greaterThan("large")`
        max-width: 316px;
        padding: 12px 0;
      `}
      ${Text} {
        font-size: 42px;
        text-align: center;
        font-weight: bold;
        color: #f1f1f1;
        ${media.greaterThan("large")`
          font-size: 52px;
        `}
      }
    }
  }
`

export const SecureYouPlaceBg = styled.section`
  position: relative;
  background-color: #171717;
  margin-top: 80px;
  ${media.greaterThan("large")`
    margin-top: 130px;
  `}
  ${SectionTitle} {
    width: 200px;
    ${media.greaterThan("large")`
    width: 360px;
   `}
  }
  ${Container} {
    transform: translateY(-25px);
    position: relative;
    ${media.greaterThan("large")`
      transform: translateY(-50px);
    `}
  }
  .banner {
    display: none;
    ${media.greaterThan("large")`
      display: block;
      border: solid 26px #f1f1f1;
      transform: rotate(-8deg);
      position: absolute;
      top: -10px;
      right: 0;
      box-shadow: 10px 10px 35px rgba(0,0,0)
    `}
  }
`

export const CurseDescriptionWrapper = styled.ul`
  margin-top: 38px;
  ${media.greaterThan("large")`
    margin-top: 58px;
  `}
  ${Text} {
    font-size: 22px;
    line-height: 1.6em;
  }
  .title {
    display: block;
    font-size: 38px;
    font-weight: bold;
  }

  .item {
    margin-top: 23px;
    ${media.greaterThan("large")`
      width: 373px;
      padding: 0 17px;
    `}
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      margin-top: 23px;
      ${media.greaterThan("large")`
        margin-left: -17px;
      `}
    }
    &:last-child {
      padding-bottom: 23px;
      &:after {
        display: none;
      }
    }
  }
`
